import React from "react"
import PropTypes from "prop-types"
import SanityBlockContent from "@sanity/block-content-to-react"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"
import { serializers } from "../../utils/serializers"

const VideoList = ({ videos }) => {
    const { i18n } = useTranslation("common")
    const language = i18n.language

    if (videos.length < 1) {
        return (
            <Row className="mb-5">
                <Col xs={12} lg={6}>
                    <br />
                    <br />
                    <h2>There are no videos matching the filter</h2>
                </Col>
            </Row>
        )
    }

    return (
        <Row className="mb-5">
            {videos.map((post, i) => (
                <Col
                    xs={12}
                    lg={6}
                    className={i % 2 !== 0 ? "pr-0" : "pl-0"}
                    key={post.node._id}
                >
                    {post.node._rawBody && (
                        <SanityBlockContent
                            blocks={post.node._rawBody[language]}
                            projectId={process.env.SANITY_PROJECTID}
                            dataset={process.env.SANITY_DATASET}
                            serializers={serializers}
                        />
                    )}

                    <div className="background">
                        <h2>{post.node.title.translate}</h2>
                    </div>
                </Col>
            ))}
        </Row>
    )
}

VideoList.propTypes = {
    videos: PropTypes.array
}

VideoList.defaultProps = {
    videos: []
}
export default VideoList
