import React, { useState } from "react"
import { graphql } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"
import { useTranslation } from "react-i18next"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar2 from "../components/Navbar2"
import CategorySelector from "../components/CategorySelector"
import VideoList from "../components/VideoList"
import { MediaQueries } from "../utils/responsive"
import { filterItems } from "../utils/filterItems"

const StyledVideoPage = styled(Container)`
  margin-top: 80px;
  margin-bottom: 80px;
  @media ${MediaQueries.laptopUp} {
    margin-top: 0;
  }
  .videoContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .background {
    min-height: 80px;
    background: ${props => props.theme.colors.red};
    display: flex;
    align-items: center;
    h2 {
      padding-left: 1rem;
      margin: 0;
    }
  }
`
const serializers = {
  types: {
    youtube: props => {
      return (
        <div className="videoContainer">
          <iframe
            src={props.node.url.replace("watch", "embed").replace("?v=", "/")}
            frameborder="0"
            allowFullScreen
            className="video"
            title={(Math.random() * Math.random()).toString()}
          ></iframe>
        </div>
      )
    },
  },
}

const VideoPage = props => {
  const { i18n } = useTranslation("blog")
  const language = i18n.language
  const allVideos = props.data.videos.edges
  const mainPost = allVideos[0].node

  const [stateVideos, setStateVideos] = useState(allVideos)

  const filterVideos = (filter, allVideos) => {
    if (filter.length > 0) {
      return allVideos.filter(prod => {
        const match = prod.node.categories.map(
          cat =>
            filter.map(item => item.label).indexOf(cat._rawTitle[language]) > -1
        )
        return match.some(i => i === true) && prod
      })
    } else {
      return allVideos
    }
  }

  const updateFilter = filter =>
    setStateVideos(filterItems(filter, allVideos, language))
  return (
    <Layout alternateLink={props.alternateLinks}>
      <SEO title="Justanatomy.com | Video" />
      <StyledVideoPage>
        <Row className="mt-4 justify-content-end">
          <Col sm={12} md={9} lg={6} xl={6}>
            <CategorySelector
              language={language}
              categories={props.data.categories.edges}
              updateFilter={updateFilter}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="px-0">
            {stateVideos.length > 0 ? (
              <>
                <SanityBlockContent
                  blocks={mainPost._rawBody[language]}
                  projectId={process.env.SANITY_PROJECTID}
                  dataset={process.env.SANITY_DATASET}
                  serializers={serializers}
                />
                <div className="background">
                  <h2>{stateVideos[0].node.title.translate}</h2>
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <VideoList videos={stateVideos} />
      </StyledVideoPage>
      <Container fluid>
        <Row>
          <Col xs={12} className="px-0 position-relative">
            <Navbar2 fixed={true} topNav={false} narrow={true} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query VideoPageQuery($language: String) {
    videos: allSanityVideo(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          _rawSlug
          _createdAt(formatString: "YYYY.MM.DD")
          title {
            translate(language: $language)
          }
          _rawBody
          categories {
            _rawTitle
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          title {
            translate(language: $language)
          }
          _rawSlug
          parents {
            _rawTitle
          }
        }
      }
    }
  }
`

export default VideoPage
